import { useRouter } from "next/router";
import Button from "@/components/Button";
import Dialog from "@/components/Dialog";
import IconInfo from "@/assets/imgs/svg/icon-info-filled.svg";
import useModalLimitFunction from "@/features/user/hooks/useModalLimitFunction";

const ModalLimitFunction = ({}): JSX.Element => {
  const router = useRouter();
  const { close, isOpen, redirectPath } = useModalLimitFunction();

  return (
    <Dialog
      isOpen={isOpen}
      onClose={close}
      icon={<IconInfo width={56} height={56} />}
      title="アカウントの切り替えが必要です"
      btns={
        <Button
          size="small"
          onClick={() => {
            router.push(`/account/pro/update?from=${redirectPath}`);
            close();
          }}
        >
          アカウントの切り替え
        </Button>
      }
      closeBtnColor="neutral"
      overlayStyle={{
        zIndex: 9999 + 1, // モーダル詳細内で表示するときなどに、その上に表示するための暫定的な処理
      }}
    >
      <p className="text-sm">
        現在のアカウントでは「プロジェクト登録」や「サンプル請求」をご利用いただけません。これらの機能をお使いいただくには、PROアカウントへの切り替えをお願いいたします。
      </p>
    </Dialog>
  );
};
export default ModalLimitFunction;
